@import '~antd/dist/antd.less';

/* Template */
body:not(.ant-scrolling-effect) {
  overflow-y: scroll !important;
}
.site-content {
  padding: 24px;
  margin: 0;
  min-height: 280px;
}
.site-content-background {
  background: #fff;
}
.site-menu-in-content-collapsible {
  position:relative;
  border-right:1px solid #f0f0f0;
}
.site-menu-in-content-collapsible .ant-menu-inline {
  border:none !important;
}
.site-menu-in-content-collapsible > .ant-layout-sider-trigger {
  position:sticky;
  margin-right: 1px;
  width:auto !important;
}
[data-theme='compact'] .ant-advanced-search-form,
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
[data-theme='compact'] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
  display: flex;
}
[data-theme='compact'] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-collapse .ant-collapse-content-no-padding {
  margin: -16px;
}
.ant-tag {
  margin-bottom:8px
}
.list-center-items .ant-row {
  justify-content:center
}
.ant-table-header {
  padding: 0 0 5px 0;
  border-bottom: 2px solid #FF5221;
}
.ant-table-header .ant-page-header-heading-left {
  margin:0
}
.ant-table-header .ant-page-header-content {
  padding: 5px 0 5px 0;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #ff5220;
  font-weight: bold;
  background-color: transparent;
}

/* Admin & Account */
.admin-container {
  padding: 10px;
}

/* Home */
.welcome-search {
  width: 100%;
  max-width: 700px;
  border:10px solid rgba(0, 0, 0, .6);
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border-radius: 10;
}
.welcome-search .ant-input-affix-wrapper {
  padding: 10px 16px;
  font-size: 18px;
}
.welcome-search-input .ant-input-affix-wrapper .ant-input {
  font-size: 18px;
}

/* Menu (media) */
.menu-item-no-hover:hover {
  border-bottom: 2px solid transparent !important;
  background-color: transparent !important;
  color: inherit !important;
}
.menu-item-no-button:hover {
  border: 0px solid transparent !important;
  background-color: transparent !important;
  color: inherit !important;
  cursor: default
}

/* Basket */
.scart-wrapper {
  position:fixed;
  top:0;
  right:0;
  z-index:101;
  height:100vh;
  width:300px;
  margin:0 -320px 0 0;
  transition:margin .5s;
  background-color:#FFF;
  box-shadow: -0px 0px 15px rgba(0,0,0,0.25)
}
.scart-wrapper.is-open {
  margin-right:0;
}
body.scart-is-open {
  overflow:hidden
}
.scart-header,
.scart-footer {background-color:#FFF; height:auto; padding:15px; line-height:normal;}
.scart-header {text-align:right;}
.scart-header span {float:left;}
.scart-content {overflow:auto;}

/* Modal */
.ant-modal {top:24px}

/* Form */
.form-uploader-avatar {display:block;}
.form-uploader-avatar .ant-upload.ant-upload-select {display:block;}
.ant-segmented-item {transition: @primary-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}

/* Global */
.img-fluid {max-width:100%; height:auto;}
.img-center {margin:0 auto; display:block;}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
.menu-item-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.photoshop-bg {
  background-image: linear-gradient(45deg, #b0b0b0 25%, transparent 25%), linear-gradient(-45deg, #b0b0b0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b0b0b0 75%), linear-gradient(-45deg, transparent 75%, #b0b0b0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.photoshop-bg-dark {
  background-color: #a4a4a4;
}

/* Reat PDF viewer */
.rpv-core__inner-pages {
  overflow: unset !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}
.rpv-core__inner-pages.rpv-core__inner-pages--horizontal {
  display: flex;
  justify-content: center;
}

@primary-color: #ff5220;